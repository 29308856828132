import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Tag,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Skeleton,
  Form,
  Divider,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig, staticConfig } from "./admin.config";
import { BackToSpecificScreen } from "../../components/Facility";

const AdminDetailTransfer = (props) => {
  const navigate = useNavigate();
  const { userData } = props;
  const { PROFILE_ID, ROLE_SHORT, session, isAdmin } = userData;

  const { state } = useLocation();
  const [useForm] = Form.useForm();
  const [transferDetail, setTransferDetail] = useState({});
  const [transferStatus, setTransferStatus] = useState(state.TRF_STATUS);
  const [inputField, setInputField] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isAdmin) return navigate("/");
    getDetailById();
  }, []);

  const getDetailById = () => {
    setIsLoading(true);

    let body = {
      profileId: PROFILE_ID,
      transferId: state.TRF_ID,
      token: session,
    };
    BOM.FetchReqAction(body, apiConfig.GetTransferDetail, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        const objDetail = res.transferDetail;
        setTransferDetail(objDetail);
        setTransferStatus(objDetail.TRF_STATUS);
        setInputField([
          {
            name: labelConfig.hastag,
            value: objDetail.TRF_ID,
            type: "general",
          },

          {
            name: labelConfig.date,
            value: objDetail?.recordStatus?.MAKER_DATE,
            type: "general",
          },
          {
            name: labelConfig.status,
            value: staticConfig.handleFlgStatus(objDetail?.TRF_STATUS),
            type: "general",
          },
          {
            name: labelConfig.trnRefenceCode,
            value: <Tag> {objDetail?.TRF_CODE}</Tag>,
            type: "general",
          },
          {
            name: labelConfig.amountTobeTransferred,
            value: (
              <Tag>{`${objDetail?.CURRENCY_SYMBOL} ${objDetail?.TRF_AMOUNT}`}</Tag>
            ),
            type: "general",
          },
          {
            name: labelConfig.transferFee,
            value: `${objDetail.CURRENCY_SYMBOL} ${objDetail?.TRF_FEE}`,
            type: "general",
          },
          {
            name: `${labelConfig.exchangeRate}`,
            value: `1 USD = ${objDetail.TRF_EXCHANGE_RATE}`,
            type: "general",
          },
          {
            name: `${labelConfig.totalAmount} in ${objDetail?.CURRENCY_SYMBOL}`,
            value: `${objDetail.CURRENCY_SYMBOL} ${objDetail?.TRF_TOTAL_AMOUNT}`,
            type: "general",
          },
          {
            name: `${labelConfig.totalAmount} in ${objDetail?.localCurrency?.CUR_SYMBOL}`,
            value: `${objDetail?.localCurrency?.CUR_SYMBOL} ${objDetail?.localTransferData?.totalAmount}`,
            type: "general",
          },
          {
            name: labelConfig.lastName,
            value: objDetail?.SENDER_LASTNAME,
            type: "sender",
          },
          {
            name: labelConfig.firstName,
            value: objDetail?.SENDER_FIRSTNAME,
            type: "sender",
          },
          {
            name: `${labelConfig.phoneNo} ( ${objDetail.SENDER_COUNTRY_PHONE_CODE} )`,
            value: objDetail?.SENDER_PHONE,
            type: "sender",
          },
          {
            name: `${labelConfig.country} ( ${objDetail?.SENDER_COUNTRY_SORTNAME} )`,
            value: (
              <>
                <img
                  className="country-flag"
                  src={objDetail.SENDER_COUNTRY_FLAG}
                  title={objDetail.SENDER_COUNTRY_NAME}
                />
              </>
            ),
            type: "sender",
          },
          {
            name: labelConfig.lastName,
            value: objDetail?.RCP_LASTNAME,
            type: "receiver",
          },
          {
            name: labelConfig.firstName,
            value: objDetail?.RCP_FIRSTNAME,
            type: "receiver",
          },
          {
            name: `${labelConfig.phoneNo} ( ${objDetail.RCP_COUNTRY_PHONE_CODE} )`,
            value: objDetail?.RCP_PHONE_NO,
            type: "receiver",
          },
          {
            name: `${labelConfig.country} ( ${objDetail?.RCP_COUNTRY_SORTNAME} )`,
            value: <>
            <img
              className="country-flag"
              src={objDetail?.RCP_COUNTRY_FLAG}
              title={objDetail?.RCP_COUNTRY_NAME}
            />
          </>,
            type: "receiver",
          },
          {
            name: labelConfig.message,
            value: objDetail?.TRF_COMMENT,
            type: "receiver",
          },
          {
            name: labelConfig.payoutMethod,
            value: objDetail.PAYMENT_TYPE_NAME,
            type: "momo",
          },
          {
            name: labelConfig.payout,
            value: `${objDetail.MOMO_NAME} ( ${objDetail.MOMO_OPERATOR} )`,
            type: "momo",
          },
        ]);
        setIsLoading(false);
      }
    });
  };

  const onUpdateStatus = (status) => {
    let body = {
      profileId: PROFILE_ID,
      transferId: state.TRF_ID,
      status,
      token: session,
    };
    // console.log(body);return;

    Notiflix.Confirm.show(
      "Are you sure?",
      `Make the transfer status ${status}`,
      "Yes",
      "No",
      function () {
        // Yes
        setIsLoading(true);
        BOM.FetchReqAction(body, apiConfig.UpdateTransferStatus, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err.message);
            setIsLoading(false);
          } else {
            BOM.AlertMsg(cnt.SUCCESS, res.message);
            // getDetailById(); // keep user in the same screen
            navigate("/admin-manage-transfer");
            setIsLoading(false);
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
        <BackToSpecificScreen route={"/admin-manage-transfer"}/>
          <Card
            className="header-solid h-full"
            bordered={false}
            // extra={
            //   ROLE_SHORT?.toLowerCase() !== "cus" && (
            //     <Button
            //       onClick={(e) =>
            //         navigate("/update-transfer", { state: transferDetail })
            //       }
            //       className="of-primaryBtnColor"
            //     >
            //       {labelConfig.update}
            //     </Button>
            //   )
            // }
          >
            <Skeleton loading={isLoading} active avatar>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  {/* <Divider orientation="left">{transferStatus?.toLowerCase()}</Divider> */}

                  <Card
                    className="card-billing-info"
                    bordered="false"
                    style={{ cursor: "initial" }}
                    extra={
                      // ROLE_SHORT?.toLowerCase() !== "cus" && (
                        <Radio.Group
                          onChange={(e) => onUpdateStatus(e.target.value)}
                          defaultValue={transferStatus?.toLowerCase()}
                          className="radio-title-button"
                          size="small"
                        >
                          <Radio.Button value={"pending"}>
                            {labelConfig.pending.toUpperCase()}
                          </Radio.Button>
                          <Radio.Button value={"paid"}>
                            {labelConfig.paid.toUpperCase()}
                          </Radio.Button>
                          <Radio.Button value={"ready"}>
                            {labelConfig.ready.toUpperCase()}
                          </Radio.Button>
                          <Radio.Button value={"completed"}>
                            {labelConfig.completed.toUpperCase()}
                          </Radio.Button>
                          <Radio.Button value={"cancelled"} danger>
                            {labelConfig.cancelled.toUpperCase()}
                          </Radio.Button>
                        </Radio.Group>
                      // )
                    }
                  >
                    <div className="col-info">
                      <Descriptions
                        title={
                          <>
                            <Divider orientation="left">
                              {labelConfig.generalInformation}
                            </Divider>
                          </>
                        }
                      >
                        {inputField.map(
                          (item, index) =>
                            item?.type?.toLowerCase() === "general" && (
                              <Descriptions.Item
                                label={item.name}
                                span={3}
                                key={index}
                              >
                                {item.value}
                              </Descriptions.Item>
                            )
                        )}
                      </Descriptions>
                      <Descriptions
                        title={
                          <>
                            <Divider orientation="left">
                              {labelConfig.senderInformation}
                            </Divider>
                          </>
                        }
                      >
                        {inputField.map(
                          (item, index) =>
                            item?.type?.toLowerCase() === "sender" && (
                              <Descriptions.Item
                                label={item.name}
                                span={3}
                                key={index}
                              >
                                {item.value}
                              </Descriptions.Item>
                            )
                        )}
                      </Descriptions>
                      <Descriptions
                        title={
                          <>
                            <Divider orientation="left">
                              {labelConfig.receiverInformation}
                            </Divider>
                          </>
                        }
                      >
                        {inputField.map(
                          (item, index) =>
                            item?.type?.toLowerCase() === "receiver" && (
                              <Descriptions.Item
                                label={item.name}
                                span={3}
                                key={index}
                              >
                                {item.value}
                              </Descriptions.Item>
                            )
                        )}
                      </Descriptions>
                      <Descriptions
                        title={
                          <>
                            <Divider orientation="left">
                              {labelConfig.recipientPayoutInformation}
                            </Divider>
                          </>
                        }
                      >
                        {inputField.map(
                          (item, index) =>
                            item?.type?.toLowerCase() === "momo" && (
                              <Descriptions.Item
                                label={item.name}
                                span={3}
                                key={index}
                              >
                                {item.value}
                              </Descriptions.Item>
                            )
                        )}
                      </Descriptions>

                      {/* {ROLE_SHORT?.toLowerCase() !== "cus" && ( */}
                        <Descriptions
                          title={
                            <>
                              <Divider orientation="left">
                                {labelConfig.recordStatus}
                              </Divider>
                            </>
                          }
                        >
                          <Descriptions.Item label={labelConfig.maker} span={3}>
                            {transferDetail?.recordStatus?.MAKER_ID}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={labelConfig.makerDate}
                            span={3}
                          >
                            {transferDetail?.recordStatus?.MAKER_DATE}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={labelConfig.checker}
                            span={3}
                          >
                            {transferDetail?.recordStatus?.CHECKER_ID}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={labelConfig.checkerDate}
                            span={3}
                          >
                            {transferDetail?.recordStatus?.CHECKER_DATE}
                          </Descriptions.Item>
                        </Descriptions>
                      {/* )} */}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

export default AdminDetailTransfer;
